import React from 'react';
import { graphql } from 'gatsby';

import Metadata from '../components/Metadata';
import BlogItem from '../components/BlogItem';
import PageLayout from '../layouts/Page';
import Button from '../components/Button';
import Search from '../components/Search';

import './Blog.scss';

export const query = graphql`
  query SanityBlogQuery($skip: Int!, $limit: Int!) {
    allSanityBlogPost(
      sort: { fields: [pinnedPost, date], order: [ASC, DESC] }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        title
        date(formatString: "MMMM DD, YYYY")
        slug {
          current
        }
        authors {
          name
          image {
            ...ImageWithPreview
          }
        }
        _rawText(resolveReferences: { maxDepth: 1000 })
        textMarkdown
        excerpt
        pinnedPost
        featuredImage {
          altText
          image {
            ...ImageWithPreview
          }
        }
      }
    }
  }
`;

const Blog = (props) => {
  const blogPosts = props.data.allSanityBlogPost.nodes;
  const { currentPage, numPages } = props.pageContext;
  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;
  const prevPage = currentPage - 1;
  const nextPage = currentPage + 1;

  return (
    <PageLayout>
      <Metadata title="Okteto Blog" />
      <div className="Blog">
        <div className="BlogHeader">
          <h1>Blog</h1>
          <Search />
        </div>
        <div className="BlogGrid">
          {blogPosts.map((post, index) => {
            return <BlogItem post={post} key={index} />;
          })}
        </div>

        {/* Paging controls, if there are multiple pages, show pager. */}
        {numPages > 1 && (
          <div className="BlogPagination">
            <div className="BlogPaginationButtons">
              {!isFirst && (
                <Button
                  className="light"
                  to={`/blog${prevPage === 1 ? '' : `/page-${prevPage}`}/`}
                  rel="prev"
                >
                  Previous
                </Button>
              )}
              {Array.from({ length: numPages }, (_, i) => (
                <Button
                  className={`NumberButton light ${
                    currentPage - 1 === i ? 'current' : ''
                  }`}
                  key={`pagination-numbers${i + 1}`}
                  to={`/blog${i === 0 ? '' : `/page-${i + 1}`}/`}
                >
                  {i + 1}
                </Button>
              ))}
              {!isLast && (
                <Button text="Next" to={`/blog/page-${nextPage}/`} rel="next">
                  Next
                </Button>
              )}
            </div>
          </div>
        )}
      </div>
    </PageLayout>
  );
};

export default Blog;
