import React, { useState, useRef, useEffect } from 'react';
import PicoSanity from 'picosanity';
import { DebounceInput } from 'react-debounce-input';

import './Search.scss';

const client = new PicoSanity({
  projectId: '6icyfeiq',
  dataset: 'production',
  apiVersion: '2021-03-25',
  token: process.env.GATSBY_SANITY_SEARCH_TOKEN,
  useCdn: true,
});

function Search() {
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [firstRun, setFirstRun] = useState(true);

  const inputRef = useRef(null);

  const handleOnChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
  };

  const highlightSearchResult = (title) => {
    if (!title) return title;

    return title.replace(
      new RegExp(searchTerm, 'gi'),
      (str) => `<span>${str}</span>`
    );
  };

  useEffect(() => {
    if (!firstRun) setSearchLoading(true);
    if (searchTerm == '') {
      setSearchLoading(false);
      return;
    }

    setFirstRun(false);
    setSearchResults([]);
    client
      .fetch(
        `
      *[!(_id in path("drafts.**")) && _type == "blogPost" && title match $term || references(*[_type=="blogTag" && title match $term]._id) || references(*[_type=="people" && name match $term]._id)]
        | 
          score(
            boost(references(*[_type=="blogTag" && title match $term]._id), 3),
            boost(references(*[_type=="blogCategory" && title match $term]._id), 2),
            references(*[_type=="people" && name match $term]._id),
            title match $term
          )
  
        | order(_score desc)

        [0...10]
  
        {
          _score,
          title,
          slug
        } 
      `,
        { term: searchTerm + '*' }
      )
      .then((articles) => {
        setSearchLoading(false);
        setSearchResults(articles);
      })
      .catch((err) => console.error('Oh noes: %s', err.message));
  }, [searchTerm]);

  return (
    <div className="Search">
      {!searchLoading && (
        <>
          {
            <svg
              width="2em"
              height="2em"
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="SearchIcon"
            >
              <path
                d="M14.0355 14.0355L20 20M15.5 10.5C15.5 13.2614 13.2614 15.5 10.5 15.5C7.73858 15.5 5.5 13.2614 5.5 10.5C5.5 7.73858 7.73858 5.5 10.5 5.5C13.2614 5.5 15.5 7.73858 15.5 10.5Z"
                stroke="currentColor"
                strokeWidth="1.2"
              ></path>
            </svg>
          }
        </>
      )}
      {searchLoading && (
        <svg
          width="2em"
          height="2em"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="SearchIcon"
        >
          <path
            d="M16.6 11.8C16.6 9.14904 14.4509 7 11.8 7C9.14904 7 7 9.14904 7 11.8C7 14.4509 9.14904 16.6 11.8 16.6"
            stroke="currentColor"
            strokeWidth="1.152"
            strokeLinecap="round"
          >
            <animateTransform
              attributeName="transform"
              type="rotate"
              dur="0.75s"
              values="0 11.75 11.75;360 11.75 11.75"
              repeatCount="indefinite"
            />
          </path>
        </svg>
      )}
      <DebounceInput
        ref={inputRef}
        aria-autocomplete="list"
        aria-controls="index-blog-output"
        aria-expanded="true"
        autoComplete="off"
        className="SearchInput"
        placeholder="Search"
        role="combobox"
        type="search"
        debounceTimeout={500}
        onChange={handleOnChange}
      />
      {searchResults.length > 0 && (
        <ul className="SearchResults">
          {searchResults.map((result) => {
            return (
              <li key={result.title}>
                <a
                  href={`/blog/${result.slug?.current}`}
                  dangerouslySetInnerHTML={{
                    __html: highlightSearchResult(result.title),
                  }}
                ></a>
              </li>
            );
          })}
        </ul>
      )}
      {searchTerm && searchResults.length === 0 && !searchLoading && (
        <div className="SearchResults">
          <p>
            No results for <strong>{searchTerm}</strong>
          </p>
        </div>
      )}
    </div>
  );
}

export default Search;
